<template>
  <div class="pa-3 d-flex flex-column align-center">
    <img src="../../assets/img/manualflow.jpg" width="100%" alt="">
      <article style="width:70%">
        <h2>Pendahuluan</h2>
        <p class="text-justify">
          Selamat datang di Web Gamifikasi, saat ini Anda masuk ke dalam sistem web sebagai 
          widyaiswara. Pada dasarnya web gamifikasi ini adalah aplikasi media pembelajaran berbasis 
          daring. Di dalamnya terdapat interaksi antara peserta, coach, dan widyaiswara. Pada umumnya 
          pembelajaran daring tidak berbeda jauh dengan pembelajaran tatap muka. Sederhananya 
          widyaiswara memberikan tugas kepada peserta lalu peserta mengerjakan tugas tersebut dengan 
          bimbingan coach. Setelah tugas rampung maka peserta melakukan pengumpulan kepada 
          widyaiswara yang selanjutnya peserta memperoleh nilai dari kegiatan tersebut. Pembelajaran 
          pada web gamifikasi ini terdapat tiga tipe yaitu individu, kelompok, dan individu-kelompok. Web 
          gamifikasi ini selain mengajak peserta untuk mengerjakan tugas yang diberikan oleh widyaiswara, 
          juga mengajak peserta untuk berkompetisi meraih poin dan lencana (badge) pencapaian dengan 
          memainkan permainan yang ada di dalam tahap tertentu pada web gamifikasi. Poin yang 
          diperoleh dari memainkan permainan dapat digunakan untuk melakukan aktivitas seperti 
          mengubah avatar, membuka akses data kasus, membuka akses berita, dan melakukan 
          pengumpulan tugas. Apabila poin peserta cukup, maka peserta tidak perlu memainkan permainan 
          untuk melakukan aktivitas tersebut. Begitupun sebaliknya, apabila poin peserta tidak cukup maka 
          peserta perlu memainkan permainan guna memperoleh poin untuk membuka aktivitas yang 
          diinginkan.
        </p>
        <br />

        <h2>Alur Penggunaan Akun Widyaiswara</h2>
        <p class="text-justify">
          Setelah widyaiswara memperoleh informasi akun pada e-mail pribadi, widyaiswara dapat 
          langsung melakukan verifikasi dan login ke dalam web gamifikasi. Pada tampilan awal setelah 
          login, widyaiswara diarahkan untuk melengkapi informasi profil seperti data pribadi dan foto diri. 
          Setelah itu widyaiswara dapat membuka pilihan menu yang berada pada sidebar sebelah kiri. 
          Melalui menu-menu tersebut, widyaiswara dapat melakukan pengelolaan mulai dari akun coach, 
          akun siswa, kelompok, hingga modul. Widyaiswara juga dapat membuat pelatihan dan mengatur 
          seluruh kebutuhan pelatihan seperti memilih modul pembelajaran, menentukan timeline setiap fase, 
          membuat kelompok dan menentukan coach, serta melakukan konfigurasi game. Setelah pelatihan 
          terbentuk, seluruh siswa dan coach akan mendapat e-mail untuk melakukan verifikasi akun dan 
          login ke dalam web gamifikasi. Secara otomatis, siswa akan mendapat modul pembelajaran 
          berupa narasi, data kasus, Informasi pendukung dan soal yang harus diselesaikan. Setelah seluruh 
          tugas dikumpulkan, widyaiswara dapat memberikan nilai baik secara individu maupun kelompok. 
          Di samping itu, widyaiswara dapat melihat leaderboard dan melakukan proses diskusi dengan 
          seluruh peserta dan coach.
        </p>
      </article>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>