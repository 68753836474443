<template>
  <div :class="light ? 'light' : 'dark'">
    <div class="pa-2 d-flex justify-end">
      <v-btn @click="switchMode('dark')" color="black" dark v-if="light"
        ><v-icon>mdi-weather-night</v-icon>dark mode</v-btn
      >
      <v-btn @click="switchMode('light')" color="blue" dark v-if="!light"
        ><v-icon>mdi-white-balance-sunny</v-icon> Light mode</v-btn
      >
    </div>
   <ManualStudent v-if="role == 4"/>
   <ManualTeacher v-if="role == 2"/>
   <ManualMentor v-if="role == 3"/>
  </div>
</template>

<script>
import ManualStudent from '../../components/Student/manualStudent.vue';
import ManualTeacher from '../../components/Teacher/manualTeacher.vue';
import ManualMentor from '../../components/Mentor/manalMentor.vue';
export default {
  components: { 
    ManualStudent,
    ManualTeacher,
    ManualMentor,
  },
  name: "manual",
  data() {
    return {
      light: true,
      role: localStorage.getItem('role_id')
    };
  },
  methods: {
    switchMode(mode) {
      if (mode == "dark") {
        this.light = false;
      } else if (mode == "light") {
        this.light = true;
      }
    }
  }
};
</script>

<style scoped>
.dark {
  background: #121212;
  color: #fff;
  transition: 0.5s;
}
.light {
  background: #fff;
  color: #121212;
  transition: 0.5s;
}
</style>
