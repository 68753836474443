<template>
  <div class="pa-3 d-flex flex-column align-center">
    <img src="../../assets/img/manualflow.jpg" width="100%" alt="">
      <article style="width:70%">
        <h2>Pendahuluan</h2>
        <p class="text-justify">
          Selamat datang di Web Gamifikasi, saat ini anda masuk kedalam sistem
          web sebagai peserta. Pada dasarnya web gamifikasi ini adalah aplikasi
          media pembelajaran berbasis daring. Di dalamnya terdapat interaksi
          antara peserta, coach, dan widyaiswara. Pada umumnya pembelajaran
          daring tidak berbeda jauh dengan pembelajaran tatap muka. sederhananya
          widyaiswara memberikan tugas kepada peserta lalu peserta mengerjakan
          tugas tersebut dengan bimbingan coach, setelah tugas rampung maka
          peserta melakukan pengumpulan kepada widyaiswara yang selanjutnya
          peserta memperoleh nilai dari kegiatan tersebut. Pembelajaran pada web
          gamifikasi ini terdapat tiga tipe yaitu individu, kelompok, dan
          individu-kelompok. Web gamifikasi ini selain mengajak peserta untuk
          mengerjakan tugas yang diberikan oleh widyaiswara, juga mengajak
          peserta untuk berkompetisi meraih poin dan lencana (badge) pencapaian
          dengan memainkan permainan yang ada didalam tahap tertentu pada web
          gamifikasi. Poin yang diperoleh dari memainkan permainan dapat
          digunakan untuk melakukan aktifitas seperti mengubah avatar, membuka
          akses data kasus, berita, dan melakukan pengumpulan tugas. Apabila
          poin peserta cukup, maka peserta tidak perlu memainkan permainan untuk
          melakukan aktifitas tersebut. Begitupun sebaliknya apabila poin
          peserta tidak cukup maka peserta perlu memainkan permainan guna
          memperoleh poin untuk membuka aktifitas yang diinginkan.
        </p>
        <br />

        <h2>Alur Penggunaan Akun Peserta</h2>
        <p class="text-justify">
          Setelah peserta memperoleh informasi akun pada email pribadi, peserta
          dapat langsung melakukan verifikasi lalu login kedalam web gamifikasi.
          Pada tampilan awal setelah login peserta diarahkan untuk melengkapi
          informasi profil mereka seperti data pribadi dan avatar. Apabila
          peserta telah melakukan pengisian profil dengan lengkap maka peserta
          akan memperoleh poin tambahan. Jika peserta belum melengkapi profil
          maka mereka akan diberi pemberitahuan untuk segera melengkapi profil
          yang berada pada bagian atas (navbar) tampilan web gamifikasi. Setelah
          itu peserta dapat langsung membuka menu penugasan yang berada pada
          sidebar sebelah kiri untuk melihat tugas yang telah diberikan oleh
          widyaiswara. Pada menu ini peserta akan diberikan narasi kasus, data
          kasus, Informasi pendukung kasus, dan daftar tugas. Untuk membuka data
          kasus, Informasi pendukung kasus, dan pengumpulan tugas peserta perlu
          menukarkan poin yang mereka untuk melakukan aktifitas tersebut. Jika
          poin mereka tidak cukup maka peserta diharuskan untuk memainkan
          permainan untuk memperoleh poin sampai poin mereka cukup untuk membuka
          data kasus dan Informasi pendukung kasus. Diakhir pembelajaran peserta
          perlu melakukan pengumpulan tugas berupa file yang diunggah kedelam
          sistem web gamifikasi. Untuk dapat melakukan pengumpulan tugas peserta
          perlu menukarkan poin yang mereka miliki, jika poin mereka tidak cukup
          mereka perlu memainkan permainan untuk memperoleh poin sehingga dapat
          membuka aktifitas pengumpulan tugas.
        </p>
      </article>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>