<template>
  <div class="pa-3 d-flex flex-column align-center">
    <img src="../../assets/img/manualflow.jpg" width="100%" alt="">
      <article style="width:70%">
        <h2>Pendahuluan</h2>
        <p class="text-justify">
        Selamat datang di Web Gamifikasi, saat ini Anda masuk ke dalam sistem web sebagai coach. 
        Pada dasarnya web gamifikasi ini adalah aplikasi media pembelajaran berbasis daring. Di 
        dalamnya terdapat interaksi antara peserta, coach, dan widyaiswara. Pada umumnya 
        pembelajaran daring tidak berbeda jauh dengan pembelajaran tatap muka. Sederhananya 
        widyaiswara memberikan tugas kepada peserta lalu peserta mengerjakan tugas tersebut dengan 
        bimbingan coach. Setelah tugas rampung maka peserta melakukan pengumpulan kepada 
        widyaiswara yang selanjutnya peserta memperoleh nilai dari kegiatan tersebut. Pembelajaran 
        pada web gamifikasi ini terdapat tiga tipe yaitu individu, kelompok, dan individu-kelompok. Web 
        gamifikasi ini selain mengajak peserta untuk mengerjakan tugas yang diberikan oleh widyaiswara, 
        juga mengajak peserta untuk berkompetisi meraih poin dan lencana (badge) pencapaian dengan 
        memainkan permainan yang ada di dalam tahap tertentu pada web gamifikasi. Poin yang 
        diperoleh dari memainkan permainan dapat digunakan untuk melakukan aktivitas seperti 
        mengubah avatar, membuka akses data kasus, membuka akses berita, dan melakukan 
        pengumpulan tugas. Apabila poin peserta cukup, maka peserta tidak perlu memainkan permainan 
        untuk melakukan aktivitas tersebut. Begitupun sebaliknya, apabila poin peserta tidak cukup maka 
        peserta perlu memainkan permainan guna memperoleh poin untuk membuka aktivitas yang 
        diinginkan.
        </p><br>
        
        <h2>Alur Penggunaan Akun Coach</h2>
        <p class="text-justify">
          Setelah coach memperoleh informasi akun pada e-mail pribadi, coach dapat langsung melakukan 
          verifikasi dan login ke dalam web gamifikasi. Pada tampilan awal setelah login, coach diarahkan 
          untuk melengkapi informasi profil seperti data pribadi dan foto. Setelah itu coach dapat membuka 
          pilihan menu yang berada pada sidebar sebelah kiri. Melalui menu-menu tersebut, coach dapat 
          melihat daftar peserta dari setiap kelompok yang dimentorinya. Coach juga dapat melihat 
          progres dari masing-masing individu maupun kelompok melalui fitur leaderboard. Selama seluruh 
          peserta mengerjakan tugas, coach dapat memantau aktivitas pengerjaannya dengan 
          memerhatikan penjadwalan yang dibuat serta penyimpanan file yang dilakukan oleh peserta. 
          Selain itu, coach juga dapat melakukan proses diskusi baik secara personal maupun secara 
          berkelompok. Hal ini dapat membantu proses bimbingan yang dilakukan oleh coach dalam 
          pembelajaran yang dilakukan. Setelah peserta berhasil mengumpulkan tugasnya, coach dapat 
          memberikan feedback berupa penilaian baik untuk tugas individu maupun tugas kelompok.
        </p>
      </article>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>